import { BottomNavigationRes } from '@/interfaces/common'
import React from 'react'

export const switchSVG = (
  item: BottomNavigationRes,
  bgColor: string,
  activeColor: string
) => {
  switch (item.alias) {
    case 'home':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="home_on" clipPath="url(#clip0_4275_2965)">
            <g id="Group 1130">
              <g id="Subtract" filter="url(#filter0_ii_4275_2965)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.10825 6.875C1.56826 8.002 1.75826 9.321 2.13825 11.958L2.41624 13.895C2.90323 17.283 3.14723 18.976 4.3222 19.988C5.49717 21 7.22013 21 10.667 21H12.879C16.3259 21 18.0489 21 19.2238 19.988C20.3988 18.976 20.6428 17.283 21.1298 13.895L21.4088 11.958C21.7888 9.321 21.9788 8.002 21.4378 6.875C20.8978 5.748 19.7468 5.062 17.4459 3.692L16.0609 2.867C13.973 1.622 12.927 1 11.773 1C10.6191 1 9.57408 1.622 7.48512 2.867L6.10016 3.692C3.80021 5.062 2.64924 5.748 2.10825 6.875Z"
                  fill={bgColor}
                />
              </g>
              <g id="Rectangle 1379" filter="url(#filter1_d_4275_2965)">
                <rect
                  x="7.77344"
                  y="15"
                  width="8"
                  height="2"
                  rx="1"
                  fill={activeColor}
                />
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_ii_4275_2965"
              x="-0.226562"
              y="-1"
              width="23"
              height="24"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-2" dy="-2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_4275_2965"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_innerShadow_4275_2965"
                result="effect2_innerShadow_4275_2965"
              />
            </filter>
            <filter
              id="filter1_d_4275_2965"
              x="6.77344"
              y="14"
              width="12"
              height="6"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_2965"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_2965"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_4275_2965">
              <rect
                width="21"
                height="21"
                fill="white"
                transform="translate(0.773438)"
              />
            </clipPath>
          </defs>
        </svg>
      )
    case 'videos':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_12852)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.57681 19.5123C6.57242 19.8359 8.59661 20 10.5931 20C12.5895 20 14.6137 19.8358 16.6095 19.5124L16.6218 19.5103C18.2571 19.2255 19.8541 17.7965 20.1818 16.3251C20.1829 16.32 20.184 16.315 20.185 16.3099C20.9157 12.7866 20.9157 9.21353 20.185 5.69015L20.1818 5.67493C19.8538 4.20332 18.2568 2.7745 16.6217 2.48975L16.6095 2.48772C14.6137 2.16407 12.5896 2 10.5931 2C8.59653 2 6.57242 2.16398 4.57689 2.48763L4.5648 2.48966C2.92937 2.77433 1.33217 4.20323 1.00438 5.67493L1.00108 5.69015C0.270474 9.21345 0.270474 12.7865 1.00108 16.3098L1.00446 16.3253C1.33251 17.7967 2.92963 19.2255 4.56463 19.5103L4.57681 19.5123ZM8.59073 14.7279C8.76382 14.902 8.99856 14.9999 9.24336 15C9.43848 15 9.62859 14.9378 9.78637 14.8223L13.9402 11.7829C14.0573 11.6972 14.1527 11.585 14.2188 11.4553C14.2848 11.3256 14.3196 11.182 14.3203 11.0363C14.321 10.8906 14.2876 10.7467 14.2229 10.6164C14.1581 10.486 14.0638 10.3729 13.9475 10.286L9.79368 7.18305C9.65634 7.08046 9.49337 7.01828 9.32295 7.00345C9.15253 6.98863 8.98137 7.02174 8.82857 7.09908C8.67576 7.17643 8.54732 7.29498 8.45757 7.4415C8.36783 7.58802 8.32031 7.75674 8.32031 7.92885V14.0712C8.32037 14.3175 8.41764 14.5537 8.59073 14.7279Z"
              fill={bgColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_1_12852">
              <rect
                width="21"
                height="21"
                fill={activeColor}
                transform="translate(0.226562)"
              />
            </clipPath>
          </defs>
        </svg>
      )
    case 'apps':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133879">
            <g id="Group 2048133864">
              <path
                id="Subtract"
                d="M19.5531 6.05C19.5531 8.28675 17.7399 10.1 15.5031 10.1C13.2664 10.1 11.4531 8.28675 11.4531 6.05C11.4531 3.81325 13.2664 2 15.5031 2C17.7399 2 19.5531 3.81325 19.5531 6.05Z"
                fill={activeColor}
              />
              <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.62742 2H7.12815C8.61907 2 9.82894 3.20988 9.82656 4.70079V7.40159C9.82656 8.8925 8.61907 10.1 7.12815 10.1H4.42498C2.93406 10.1 1.72656 8.8925 1.72656 7.40159V4.90085C1.72656 3.298 3.02456 2 4.62742 2ZM4.62742 11.9H7.12815C8.61907 11.9 9.82894 13.1099 9.82656 14.6008V17.3016C9.82656 18.7925 8.61907 20 7.12815 20H4.42498C2.93406 20 1.72656 18.7925 1.72656 17.3016V14.8009C1.72656 13.198 3.02456 11.9 4.62742 11.9ZM17.0276 11.8999H14.5268C12.924 11.8999 11.626 13.1979 11.626 14.8008V17.3015C11.626 18.7924 12.8335 19.9999 14.3244 19.9999H17.0276C18.5185 19.9999 19.726 18.7924 19.726 17.3015V14.6007C19.7284 13.1098 18.5185 11.8999 17.0276 11.8999Z"
                fill={bgColor}
              />
            </g>
          </g>
        </svg>
      )

    case 'games':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="game_on" clipPath="url(#clip0_4275_13658)">
            <path
              id="Union"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.3843 2C10.8959 2 10.5 2.3959 10.5 2.88427V4.30594H7.37387C5.54403 4.30594 3.8199 5.07697 2.51862 6.47565C1.21734 7.87433 0.5 9.72751 0.5 11.6943V11.7051C0.5 13.6692 1.21734 15.5224 2.51862 16.9211C3.8199 18.3198 5.54403 19.0908 7.37387 19.0908H14.6236C16.4535 19.0908 18.1801 18.3225 19.4814 16.9238C20.7827 15.5251 21.5 13.6719 21.5 11.7051V11.6943C21.5 9.72751 20.7827 7.87433 19.4814 6.47565C18.1801 5.07697 16.456 4.30594 14.6261 4.30594H12.2685V2.88427C12.2685 2.3959 11.8726 2 11.3843 2Z"
              fill={bgColor}
            />
            <g id="Union_2" filter="url(#filter0_d_4275_13658)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.97104 12.4629C5.27356 12.4629 5.5186 12.7085 5.51789 13.011C5.51718 13.313 5.76144 13.5585 6.06347 13.5592L6.42221 13.56C6.72589 13.5607 6.97265 13.3151 6.97337 13.0114C6.97408 12.7083 7.22548 12.4629 7.52865 12.4629C7.83841 12.4629 8.09506 12.2118 8.09506 11.902V11.5652C8.09506 11.2571 7.84531 11.0074 7.53724 11.0074C7.22865 11.0074 6.97869 10.7568 6.97942 10.4483L6.97955 10.3953C6.98035 10.058 6.70758 9.78396 6.37031 9.78317L6.13932 9.78263C5.80032 9.78183 5.52484 10.0615 5.52404 10.4005C5.52326 10.7329 5.25358 11.0074 4.9212 11.0074C4.58826 11.0074 4.31836 11.2773 4.31836 11.6102V11.8632C4.31836 12.1944 4.58686 12.4629 4.91806 12.4629H4.97104ZM12.8409 11.6702C12.8409 12.8308 13.785 13.7723 14.9429 13.7723C16.1035 13.7723 17.045 12.8308 17.045 11.6702C17.045 10.5096 16.1035 9.56812 14.9429 9.56812C13.7823 9.56812 12.8409 10.5096 12.8409 11.6702ZM14.0583 11.6729C14.0583 11.1859 14.456 10.7882 14.9429 10.7882C15.4326 10.7882 15.8276 11.1859 15.8276 11.6729C15.8276 12.1599 15.4299 12.5576 14.9429 12.5576C14.456 12.5576 14.0583 12.1599 14.0583 11.6729Z"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13658"
              x="3.31836"
              y="8.56812"
              width="16.7266"
              height="8.2041"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13658"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13658"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_4275_13658">
              <rect
                width="21"
                height="21"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      )

    case 'blogs':
      return (
        <svg
          width="24"
          height="21"
          viewBox="0 0 24 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133881">
            <g id="Group 2048133796">
              <path
                id="Vector"
                d="M15.3592 20C15.0619 20 14.7646 19.9147 14.5064 19.7453L11.4195 17.7198H5.99203C3.64075 17.7198 1.72656 15.8068 1.72656 13.4543V6.26428C1.72656 3.913 3.63957 2 5.99203 2H17.2035C19.5548 2 21.469 3.913 21.469 6.26546V13.4543C21.469 15.8056 19.556 17.7198 17.2035 17.7198H16.9145V18.4447C16.9145 19.0168 16.6018 19.5404 16.0984 19.8128C15.8662 19.9384 15.6127 20 15.3592 20Z"
                fill={bgColor}
              />
            </g>
            <g id="Union" filter="url(#filter0_d_4275_13696)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.1527 9.76804H15.0194C15.5429 9.76804 15.9658 9.34398 15.9658 8.82042C15.9658 8.29686 15.5417 7.8728 15.0182 7.8728H8.1527C7.62914 7.8728 7.20508 8.29686 7.20508 8.82042C7.20508 9.34398 7.62914 9.76804 8.1527 9.76804ZM8.1527 12.7613H11.0429C11.5665 12.7613 11.9905 12.3373 11.9905 11.8137C11.9905 11.2901 11.5665 10.8661 11.0429 10.8661H8.1527C7.62914 10.8661 7.20508 11.2901 7.20508 11.8137C7.20508 12.3373 7.62914 12.7613 8.1527 12.7613Z"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13696"
              x="6.20508"
              y="6.8728"
              width="12.7607"
              height="8.88843"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13696"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13696"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )

    case 'my':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133882">
            <g id="my_svg">
              <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9399 7.38207C15.9399 10.3498 13.5256 12.7641 10.5579 12.7641C7.58994 12.7641 5.17558 10.3498 5.17578 7.38207C5.17578 4.41436 7.59015 2 10.5579 2H15.9399V7.38207ZM5.00199 13.4492H16.0664C17.8726 13.4492 19.3419 14.9185 19.3419 16.7247C19.3419 18.5308 17.8726 20.0001 16.0664 20.0001H5.00199C3.19584 20.0001 1.72656 18.5308 1.72656 16.7247C1.72656 14.9185 3.19584 13.4492 5.00199 13.4492Z"
                fill={bgColor}
              />
              <g id="Vector" filter="url(#filter0_d_4275_13704)">
                <path
                  d="M10.2607 16.9999C10.2607 17.5513 10.7186 18 11.2817 18H15.2398C15.8028 18 16.2607 17.5513 16.2607 16.9999V16H11.2817C10.7186 16.0003 10.2607 16.4484 10.2607 16.9999Z"
                  fill={activeColor}
                />
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13704"
              x="9.26074"
              y="15"
              width="10"
              height="6"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13704"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13704"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )
    case 'circle':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="circle_svg">
            <g id="Group 2048133865">
              <g id="Subtract" filter="url(#filter0_ii_4275_13634)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.4874 19.2522C15.2515 19.2522 19.1135 15.3901 19.1135 10.6261C19.1135 5.86203 15.2515 2 10.4874 2C5.72335 2 1.86133 5.86203 1.86133 10.6261C1.86133 15.3901 5.72335 19.2522 10.4874 19.2522ZM11.3954 7.44802C12.1476 7.44802 12.7574 6.83823 12.7574 6.08601C12.7574 5.33379 12.1476 4.724 11.3954 4.724C10.6432 4.724 10.0334 5.33379 10.0334 6.08601C10.0334 6.83823 10.6432 7.44802 11.3954 7.44802Z"
                  fill={bgColor}
                />
              </g>
              <path
                id="Subtract_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5477 5.66943C17.8168 5.63153 18.0503 5.61706 18.247 5.6227C18.4458 5.62841 18.5672 5.652 18.6366 5.67252C18.6311 5.74465 18.61 5.86648 18.5446 6.05437C18.3812 6.52385 18.0141 7.16756 17.4008 7.94706C16.1847 9.49252 14.1958 11.3263 11.7113 13.0392C9.22685 14.7521 6.80568 15.9587 4.92877 16.5457C3.98209 16.8418 3.24992 16.9559 2.75303 16.9416C2.55417 16.9359 2.43278 16.9123 2.36341 16.8918C2.3689 16.8197 2.39004 16.6979 2.45542 16.51C2.57634 16.1625 2.80882 15.7196 3.17014 15.1969C2.90165 14.768 2.66982 14.3138 2.47904 13.8387C1.19353 15.491 0.670531 16.9012 1.21158 17.6859C2.30755 19.2756 7.35459 17.6972 12.4844 14.1605C17.6143 10.6238 20.8844 6.46807 19.7884 4.8784C19.3217 4.20142 18.1384 4.09899 16.5373 4.47768C16.9087 4.84316 17.2471 5.24203 17.5477 5.66943ZM18.6343 5.58624C18.6344 5.58618 18.6349 5.58791 18.6356 5.59162C18.6345 5.58816 18.6342 5.5863 18.6343 5.58624ZM2.2836 16.859C2.28369 16.8589 2.28531 16.8599 2.28817 16.8621C2.28493 16.8602 2.2835 16.8591 2.2836 16.859ZM2.36572 16.9781C2.36561 16.9782 2.36507 16.9764 2.36443 16.9727C2.3655 16.9762 2.36582 16.978 2.36572 16.9781ZM18.7118 5.70223C18.7151 5.70415 18.7165 5.70527 18.7164 5.70535C18.7163 5.70543 18.7147 5.70447 18.7118 5.70223Z"
                fill={activeColor}
              />
              <g id="Ellipse 518" filter="url(#filter1_d_4275_13634)">
                <circle
                  cx="11.3952"
                  cy="6.08589"
                  r="1.36201"
                  fill={activeColor}
                />
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_ii_4275_13634"
              x="-0.138672"
              y="0"
              width="21.252"
              height="21.2522"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-2" dy="-2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_4275_13634"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="2" dy="2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_innerShadow_4275_13634"
                result="effect2_innerShadow_4275_13634"
              />
            </filter>
            <filter
              id="filter1_d_4275_13634"
              x="9.0332"
              y="3.72388"
              width="6.72363"
              height="6.72412"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13634"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13634"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )

    case 'bts':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133883">
            <path
              id="Union"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 2C3.89543 2 3 2.89543 3 4V18C3 19.1046 3.89543 20 5 20H17C18.1046 20 19 19.1046 19 18V4C19 2.89543 18.1046 2 17 2H5ZM16 15.5H6C5.17157 15.5 4.5 16.1716 4.5 17C4.5 17.8284 5.17157 18.5 6 18.5H16C16.8284 18.5 17.5 17.8284 17.5 17C17.5 16.1716 16.8284 15.5 16 15.5ZM16 18C16.5523 18 17 17.5523 17 17C17 16.4477 16.5523 16 16 16C15.4477 16 15 16.4477 15 17C15 17.5523 15.4477 18 16 18Z"
              fill={bgColor}
            />
            <g id="BT" filter="url(#filter0_d_4275_13647)">
              <path
                id="Vector"
                d="M11.1973 6H16.0002V7.23465H14.3888V11H12.8087V7.23465H11.1973V6Z"
                fill={activeColor}
              />
              <path
                id="Vector_2"
                d="M6 6H8.9578C9.45076 6 9.82862 6.11937 10.0914 6.35812C10.3565 6.59686 10.489 6.89245 10.489 7.24488C10.489 7.54047 10.3948 7.794 10.2065 8.00546C10.0809 8.14643 9.89722 8.25784 9.65539 8.3397C10.0228 8.4261 10.2925 8.57503 10.4646 8.78649C10.639 8.99568 10.7262 9.25944 10.7262 9.57776C10.7262 9.83697 10.6646 10.07 10.5413 10.2769C10.4181 10.4839 10.2495 10.6476 10.0356 10.7681C9.90303 10.8431 9.70306 10.8977 9.43565 10.9318C9.07987 10.9773 8.84386 11 8.72759 11H6V6ZM7.594 7.96112H8.28113C8.52761 7.96112 8.69852 7.92019 8.79386 7.83834C8.89152 7.75421 8.94036 7.6337 8.94036 7.47681C8.94036 7.33129 8.89152 7.2176 8.79386 7.13574C8.69852 7.05389 8.5311 7.01296 8.29159 7.01296H7.594V7.96112ZM7.594 9.92565H8.39972C8.67178 9.92565 8.86362 9.87904 8.97524 9.78581C9.08685 9.69031 9.14266 9.56298 9.14266 9.40382C9.14266 9.25603 9.08685 9.13779 8.97524 9.04911C8.86595 8.95816 8.67294 8.91269 8.39623 8.91269H7.594V9.92565Z"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13647"
              x="5"
              y="5"
              width="14"
              height="9"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13647"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13647"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )

    case 'anwang':
      return (
        <svg
          width="26"
          height="21"
          viewBox="0 0 26 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133880">
            <path
              id="Vector"
              d="M18.7608 3.6204L20.8164 9.0111L23.3814 10.0908C25.3431 10.821 25.8775 13.2771 23.6241 13.7815C22.2907 14.0802 20.9912 14.3209 19.7087 14.5067C19.3861 18.1784 18.3503 21.3387 15.5696 19.4209C14.4808 18.7487 13.6778 17.7958 12.7199 17.7958C11.762 17.7958 9.24495 20.5956 7.73171 19.8834C6.48317 19.326 6.00174 16.6192 5.83893 14.4218C4.62069 14.2417 3.40736 14.0299 2.20018 13.7865C1.14841 13.5798 0.550105 12.4741 0.772845 11.689C0.996583 10.9019 1.52696 10.3625 2.20617 10.1068L4.97793 9.00011C5.79698 6.82765 6.46619 5.06171 6.98559 3.7013C7.76468 1.66069 9.81328 1.66069 11.0738 2.66052C11.724 3.17692 12.1535 3.61441 12.7039 3.62539C13.2533 3.63538 13.7826 3.17592 14.4619 2.66052C15.7753 1.66069 17.6821 1.65769 18.7608 3.6204Z"
              fill={bgColor}
            />
            <g id="Vector_2" filter="url(#filter0_d_4275_13691)">
              <path
                d="M14.575 15.8342C14.2354 16.1209 14.0676 16.4095 15.0325 16.9908C16.218 17.4991 16.8666 17.053 17.2094 16.4667C17.6792 15.663 17.0831 15.0832 16.1773 15.298C15.4805 15.4632 14.7472 15.6893 14.575 15.8342ZM9.65453 15.4279C8.33971 15.0452 7.43715 16.1821 8.55384 16.9747C8.93564 17.2457 9.49116 17.3278 10.291 16.9848C10.523 16.8449 10.6871 16.7201 10.7997 16.6088C11.2629 16.1504 10.2802 15.6101 9.65453 15.4279Z"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13691"
              x="7.0498"
              y="14.2529"
              width="13.3242"
              height="5.95972"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13691"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13691"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )

    default:
      return (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.5" cy="10.5" r="10.5" fill="#AAAAAA" />
        </svg>
      )
  }
}
