import React, { useState, useMemo, useEffect } from 'react'
import classnames from 'classnames'
import Footer from '@/components/footer'
import styles from './index.module.scss'
import { useOutlet } from 'react-router-dom'
import { useGlobalConfig } from '@/hooks/useGlobalConfig'
import { Swiper, SwiperSlide } from 'swiper/react'
import { handleNativeRouteJump } from '@/utils/native'
import { useNavigate, useLocation } from 'react-router-dom'
import { Autoplay } from 'swiper/modules'
import Image from '@/components/image'

interface LayoutProps {
  isShowFooter?: boolean
  needScroll?: boolean
  needLinearBg?: boolean
}

/**
 * 公共layout
 * @param LayoutProps
 * @returns Layout
 */
export default function Layout(props?: LayoutProps) {
  const [state, setState] = useState<boolean>(true)
  const [show, setShow] = useState<boolean>(true)
  const location = useLocation()
  const router = useNavigate()
  const { data: globalConfig } = useGlobalConfig()
  const { isShowFooter, needScroll, needLinearBg } = props || {
    isShowFooter: false,
    needScroll: true,
    needLinearBg: false
  }
  const children = useOutlet()
  const appdownUrl = useMemo(
    () => globalConfig?.config?.find((item) => item.key === 'app_download'),
    [globalConfig]
  )
  const floatList = useMemo(() => globalConfig?.float_adv || [], [globalConfig])
  useEffect(() => {
    // 如果在登录页面 当前页面还没有关闭(token 失效等情况造成)-需要手动关闭
    const matchRouter = [
      '/',
      '/videos',
      '/anwang',
      '/apps',
      '/bts',
      '/blogs',
      '/my'
    ]
    if (!matchRouter.includes(location.pathname)) {
      setShow(false)
    }
  }, [location.pathname])
  return (
    <>
      <main
        className={classnames({
          [styles.main]: true,
          [styles.hide_footer]: !isShowFooter,
          [styles.linear_bg]: needLinearBg
        })}
        style={{ overflowY: needScroll ? 'auto' : 'hidden' }}
      >
        {children}
        {show && appdownUrl?.value && (
          <div
            className={styles.globalDownload}
            onClick={() => {
              window.open(appdownUrl.value as never)
            }}
          >
            <img src={require('./images/btn_down.png')} />
          </div>
        )}

        {state && (
          <div className={styles.advPop}>
            <div
              className={styles.close}
              onClick={(e) => {
                e.stopPropagation()
                setState(false)
              }}
            ></div>
            <Swiper
              loop={floatList.length > 1}
              autoplay={{ delay: 3000 }}
              spaceBetween={10}
              modules={[Autoplay]}
              nested={true}
              className="h-[100%]"
            >
              {floatList &&
                floatList.map((o) => (
                  <SwiperSlide
                    key={o.id}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleNativeRouteJump(o as any, router)
                    }}
                    style={{ position: 'relative' }}
                  >
                    <Image src={o.image} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}
      </main>
      {isShowFooter && <Footer />}
    </>
  )
}
