import useStateCallback from '@/hooks/useStateCallback'
import { getDecryptImage } from '@/utils/aes'
import React, {
  HTMLAttributes,
  ImgHTMLAttributes,
  memo,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './index.module.scss'
import { useRequest } from 'ahooks'

const { siteName } =
  require(`@/themes/${process.env.NEXT_PUBLIC_PLATFORM}/config`).default
interface Props extends ImgHTMLAttributes<any>, HTMLAttributes<any> {
  rootMargin?: string
  // 是否需要显示过度加载动画
  showAnimation?: boolean
  // 新增一个预加载属性
  preload?: boolean
  src: string
}
const Image = memo(function Image({
  alt,
  rootMargin,
  preload = false,
  ...props
}: Props) {
  const { src, className, showAnimation } = props
  delete props?.showAnimation
  // 获取传入动画名字
  const [initSrc, setInitSrc] = useStateCallback<any>({ src: src })
  const { ref: setRef, inView } = useInView({
    threshold: 0.3,
    rootMargin: rootMargin || '0px 0px 100px 0px'
  })

  // 如果不是静态导入，并且url 有http 表示远程图片
  const isRemote = useMemo<boolean>(() => /^http/i.test(src?.toString()), [src])
  const imgRef = useRef<any>(null)
  // 请求图片并解密返回
  const {
    data: image = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    runAsync
  } = useRequest(getDecryptImage, {
    manual: true,
    cacheKey:
      isRemote && src
        ? src.toString().substring(0, src.toString().indexOf('?'))
        : '',
    cacheTime: -1,
    staleTime: -1
  })

  const fetchAndDecryptImage = () => {
    runAsync(src?.toString())
      .then(() => {
        if (imgRef.current && showAnimation) {
          imgRef.current?.classList?.add(styles.transform_image)
        }
      })
      .catch((error) => {
        console.info(error)
      })
  }

  const isHitCache = useMemo(() => {
    // 如果是远程图片，并已经有缓存
    if (isRemote && image && image.startsWith('blob:')) {
      return true
    } else {
      return false
    }
  }, [isRemote, image])

  const needGetImage = useMemo(() => {
    const initPath =
      isRemote && initSrc
        ? initSrc.src.substring(0, initSrc.src.indexOf('?'))
        : ''
    const srcPath =
      isRemote && src
        ? src.toString().substring(0, src.toString().indexOf('?'))
        : ''
    // 注意这个判断顺序不能修改
    // 如果不是远程图片直接返回false,或者是远程图片但是已经有缓存
    if (!isRemote) {
      return false
    } else if (isRemote && initPath !== srcPath) {
      setInitSrc({ src })
      return true
    } else if (isHitCache) {
      return false
    } else if (preload) {
      // 如果需要优先加载-比如首页启动图片 不能在可见于才加载
      return true
    } else if (inView) {
      // 如果在可见区域需要执行获取图片
      return true
    } else {
      // 图片地址发生改变也需要重新请求
      return false
    }
  }, [isRemote, preload, src, inView, isHitCache])

  useEffect(() => {
    if (needGetImage) {
      fetchAndDecryptImage()
    }
  }, [needGetImage])

  return (
    <img
      ref={(img) => {
        setRef(img)
        imgRef.current = img
      }}
      {...props}
      src={isRemote ? image : src}
      alt={alt || `${siteName}`}
      title={alt || `${siteName}`}
      className={[
        className,
        styles.img_default,
        showAnimation ? styles.animation_img : '',
        isHitCache ? styles.transform_image : ''
      ].join(' ')}
    />
  )
})

export default Image
